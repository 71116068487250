import React from "react";
import { Container } from "@material-ui/core";
import { COLORS } from "../../enums/layout-enums";
interface IPrivacyPolicyProps {}

const PrivacyPolicy: React.FC<IPrivacyPolicyProps> = () => {
  return (
    <Container
      style={{ minHeight: "100vh", backgroundColor: COLORS.WHITE_PAPER }}
    >
      <h1>PRIVACY PRACTICE STATEMENT</h1>
      <section>
        <article>
          <article>
            <u>
              <b>Effective Date: September 2009</b>
            </u>
            <br></br>
            <u>
              <b>Privacy Practice Statement:</b>
            </u>
          </article>
          <br></br>
          <article>
            Predictive Compliance LLC (“PCL”) believes that understanding what
            our customers want regarding products and services is very important
            and valuable to PCL; and information is an important part of
            building an interesting, valuable and useful on-line environment.
            PCL wants to contribute to providing a safe and secure environment
            for you if you choose to become an interactive user of our website
            (“Site”). PCL has designed its Site with this belief in mind, and
            has created this page so that you can have easy access to PCL’s
            stated privacy practice. At any time, however, PCL may change its
            privacy practice and privacy practice statement. In the event PCL
            does so, PCL will post these changes here promptly.
          </article>
          <article>
            <br></br>
            Your California Privacy Rights:
            <br></br>California Civil Code Section 1798.83, also known as the
            “Shine The Light” law, permits our customers who are California
            residents to request and obtain from us once a year, free of charge,
            information about the personal information (if any) we disclosed to
            third parties for direct marketing purposes in the preceding
            calendar year. If applicable, this information would include a list
            of the categories of personal information that was shared and the
            names and addresses of all third parties with which we shared
            information in the immediately preceding calendar year. If you are a
            California resident and would like to make such a request, please
            submit your request in writing to:
            <p>
              Predictive Compliance LLC <br></br>1040 Blake St. Suite 105 A{" "}
              <br></br>Denver, CO 80202
            </p>
            <section>
              Contents: <br></br>What information does PCL collect on-line?
              <br></br> What sort of information will PCL not collect?<br></br>{" "}
              How does PCL use the information it collects?<br></br> “Cookies”
              or Web Beacons — what are they and how does PCL use them?<br></br>{" "}
              How does PCL use e-mail?<br></br> What information may PCL share
              with others?
              <br></br> What information will PCL not share with others (with
              special attention to children)?<br></br> Choices for your Site
              Interaction.<br></br> How to contact PCL with questions or
              comments.<br></br> What information does PCL collect on-line?
              <br></br>
              <br></br> When you visit PCL’s Site, PCL may ask you to provide
              information by filling out and submitting an on-line form. This
              requested information may include your name, phone number(s),
              e-mail address, and mailing address. For privacy purposes all
              information relating to our customers is stored in a highly secure
              server that is not accessible via the Internet and will not be
              shared with any third parties. You can always choose whether or
              not to disclose any or all of the information requested; however,
              certain information may be required in order to be able to respond
              to your request. If you do not want to disclose any requested
              information over the Site you may contact PCL’s customer service
              center at 801-994-6455 to complete your order or request
              information.<br></br>
              <br></br> Additionally, PCL automatically gathers certain usage
              information regarding the number and timing of visitors to the
              Site and the number and type of customers that access and obtain
              PCL’s products. PCL only uses and analyzes such information in the
              aggregate, not at the individual level.
              <br></br>
              <br></br>Therefore, any individual, personal information, such as
              your name and e-mail address, are not included in this analysis.
              Information we collect:<br></br>
              <br></br> We may elect to do one or more of the following with
              respect to the information we collect:<br></br>
            </section>
            <ul>
              <li>
                We collect the information you give us in order to provide you
                products and services, inform you about our products and fulfill
                your orders and service requests.
              </li>
              <li>
                We use cookies and other Internet technologies, including web
                beacons, to manage our Site and e-mail programs, to offer you
                personalized experiences and to help us manage our Site.
              </li>
              <li>
                When you access our Site, we use your IP address for internal
                purposes and to customize your browsing experience. We also
                utilize your IP address for security purposes and to protect
                your information.
              </li>
            </ul>
            <br></br>
            <section>
              What sort of information will PCL not collect?<br></br>The only
              information PCL may collect is information that you voluntarily
              provide to PCL, which may include your acceptance of “cookies” or
              web beacons.
            </section>
            <br></br>
            <section>
              “Cookies” or Web Beacons — if PCL sends you cookies, what are they
              and how does PCL use them? What are Web Beacons and how does PCL
              use them?<br></br>Cookies are small pieces of text that store
              information specific to your browsing session with PCL. A cookie
              does not contain code or any other malicious data. We utilize this
              recurring information to customize your browser experience while
              you are utilizing PCL resources, and it ensures the proper
              credentials are being used to secure your information from
              unwanted viewers. The majority of information PCL retrieves from
              your cookies is contained in “Session Cookies” and is not
              permanently stored or remembered.<br></br>
              <br></br>A Web Beacon is an embedded object that is usually
              attached to an image or framing object. In many cases, this
              embedded code pulls an image or other object from a third party
              site and is generally used in user tracking.<br></br>
              <br></br>PCL does not utilize “third party” cookies or “web
              beacons.” All data is solely used at PCL for your security and
              browsing customization.
            </section>
            <br></br>
            <br></br>
            <section>
              How does PCL use the information it collects?<br></br>
              PCL may use the information it collects to improve its Site,
              enhance PCL’s existing product offerings, and to introduce new
              product offerings.<br></br>
              <br></br>
              How we use personal information:<br></br>
              PCL may elect to do one or more of the following:<br></br>
              <ul>
                <li>
                  We use personal information to deliver the services and
                  support, or to carry out the transactions you have requested.
                </li>
                <li>
                  We use the information collected to send communications to
                  you, such as product safety information, your transactions
                  status (for example, order confirmations), information about
                  products and services available from PCL and its corporate
                  affiliates, promotional offers, and surveys.
                </li>
                <li>
                  We use the information to customize, analyze, and improve our
                  products, services (including our Site), technologies,
                  communications and relationship with you.
                </li>
                <li>
                  We use the information to enforce our conditions of sale, Site
                  terms and/or separate contracts (if applicable) with you.
                </li>
                <li>
                  We use information to prevent fraud and other prohibited or
                  illegal activities.
                </li>
              </ul>
            </section>
            <section>
              How does PCL use e-mail?<br></br>
              PCL encourages you to e-mail PCL with your questions or comments.
              PCL reads every e-mail it receives. If you request to have your
              e-mail address added to our mailing list, PCL will periodically
              send new information to you. If you want your e-mail address
              removed from the list at any time, just instruct us to do so by
              e-mail.
            </section>
            <section>
              <br></br>What information may PCL share with others?<br></br>
              PCL will not share, sell or trade e-mail addresses, which are
              personal to you, and that are collected as part of a survey, with
              others. Except to verify credit card information to complete your
              product transaction, PCL will not use or release to others any
              credit card or financial information for any purpose. For privacy
              purposes, all information relating to our customers is stored on a
              highly secure server that is not accessible via the Internet and
              will not be shared with any third parties.<br></br>
              <br></br>
            </section>
            <section>
              Protecting personal information: We understand the importance of
              ensuring that your personal information is secure and protected.
              We use a variety of security technologies and procedures to help
              protect personal information from unauthorized access, use, and
              disclosure.<br></br>
            </section>
            <section>
              <br></br>
              Disclosing personal information:<br></br>
              <ul>
                <li>
                  We share your personal information with corporate affiliates
                  to carry out transactions you request or to make our business
                  or that of our corporate affiliates more responsive to your
                  needs.
                </li>
                <li>
                  We may share your personal information with business partners
                  that help PCL carry out transactions you request or that help
                  PCL to customize, analyze and/or improve our communications or
                  relationship with you, and then only with business partners
                  who share PCL's commitment to protecting your personal
                  information.
                </li>
                <li>
                  We may disclose your personal information in connection with
                  law enforcement, fraud prevention, or other legal action as
                  required by law or regulation, or if PCL reasonably believes
                  it is necessary to protect PCL, its customers or the public.
                </li>
                <li>
                  Except as described above, we will not disclose your personal
                  information to other third parties unless you have provided
                  consent.
                </li>
              </ul>
            </section>
            <section>
              Special attention to children:<br></br>PCL takes special care to
              protect the safety and privacy of young people accessing and using
              our products. PCL does not specifically collect information about
              children and believes that children should get their parents’
              consent before giving out any personal information. PCL encourages
              you to participate in and supervise your child’s Internet
              experience.
            </section>
            <section>
              <br></br>
              Choices for your Site interactions:<br></br>
              <ul>
                <li>
                  You may instruct PCL to stop sending you certain promotional
                  materials (e.g., e-mail messages, catalogues) by contacting
                  PCL by e-mail, telephone or through the contact form on the
                  web site.
                </li>
                <li>
                  You may choose to view or edit your personal information,
                  which has been stored online, by visiting the my account
                  section of the Site. For the security of your account, you
                  will be required to sign in with your login ID and password.
                </li>
                <li>
                  You may contact PCL to determine the types of personal
                  information that we process and to correct or update your
                  personal information.
                </li>
              </ul>
            </section>            
          </article>
        </article>
      </section>
    </Container>
  );
};

export default PrivacyPolicy;
