import React from "react";
import { Container } from "@material-ui/core";
import { COLORS } from "../../enums/layout-enums";
interface IPricingAndLicensingProps {}

const PricingAndLicensing: React.FC<IPricingAndLicensingProps> = () => {
  return (
    <Container
      style={{ minHeight: "100vh", backgroundColor: COLORS.WHITE_PAPER }}
    >
      <h1>Pricing and Licensing</h1>
      <section>
        <article>
          Predictive Compliance prices include:
          <ul>
            <li>
              a one time Registration Fee based on the number of MSHA Mine IDs;
              and
            </li>
            <li>
              an Annual Subscription Fee based on Controller citation history
            </li>
          </ul>
          A separate, Non-Controller Subscription can be purchased for
          consultants, attorneys or other specialists with prices based on
          Log-in requirements. Please contact us for a Service Proposal and
          demonstration at:{" "}
          <a href="mailto:fieldsupport@iwtwireless.com?subject=Pricing And Licensing Inquiry">
          fieldsupport@iwtwireless.com
          </a>
        </article>
      </section>
    </Container>
  );
};

export default PricingAndLicensing;
